import React from 'react'
import TabPanel from '../../Utils/Components/TabPanel'
import PropTypes from "prop-types";
import a11yProps from '../../Utils/Components/TabPanelAllyProp'
import { Tab, Tabs } from '@mui/material';
import ProjectsTable from '../Projects/ProjectsTable';
import MembersTable from "../GeneralSettings/Members"
import ReportSetting from '../GeneralSettings/Report';
import { useParams } from 'react-router-dom';
import CompanyAnalytics from './CompanyAnalytics';
import Maps from './Maps';


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default function CompanyTab() {
    const [value, setValue] = React.useState(0);
    const { id } = useParams()
    function handleChange(event, newValue) {
        setValue(newValue);
    }
  return (
    <div>
        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant={"scrollable"}
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                             <Tab label="Map" {...a11yProps(0)} style={{ minWidth: "20%" }} />
                                             <Tab label="Analytics  " {...a11yProps(1)} style={{ minWidth: "20%" }} />

                                            <Tab label="Projects" {...a11yProps(2)} style={{ minWidth: "20%" }} />
                                            <Tab label="Company  Member" {...a11yProps(3)} style={{ minWidth: "20%" }} />
                                                <Tab label="Reports" {...a11yProps(4)} style={{ minWidth: "20%" }} />

                                               
                                        </Tabs>
                                        <TabPanel value={value} index={0}>
                                         <Maps/>
                                         {/* <CompanyAnalytics/> */}
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <CompanyAnalytics/>
                                        {/* <Maps/> */}
                                         {/* <ProjectsTable/> */}
                                         
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <ProjectsTable/>
                                            {/* <MembersTable/> */}
                                           
                                           
                                        </TabPanel>
                                        {/* <TabPanel value={value} index={2}>
                                        <MembersTable />
                                        </TabPanel> */}
                                        <TabPanel value={value} index={3}>
                                           {/* <ReportSetting/> */}
                                           <MembersTable/>
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                        {/* <CompanyAnalytics/> */}
                                        <ReportSetting/>

                                        </TabPanel>
                                        {/* <TabPanel value={value} index={5}>
                                            <ActivityLog />
                                        </TabPanel> */}
                                        <TabPanel value={value} index={6}>
                                            Item Seven
                                        </TabPanel>
    </div>
  )
}
