import { Grid } from '@mui/material'
import React from 'react'
import BudgetAnalytics from './BudgetAnalytics'
import Timeline from './Timeline'
import Financiers from './Financiers'


function ProgressAnalytics() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>

          <div class="card-body px-4">
            <h4 class="fs-18 font-w600 mb-3 text-nowrap">Budget Analysis</h4>
            <div class="d-flex align-items-end mt-1 justify-content-between flex-direction-column">
              <span><small class="text-primary">estimated expenditure  </small></span>
              {/* <h4 class="mb-0 fs-32 font-w800">42</h4> */}
            </div>
            <div class="progress default-progress">
       
              <div class="progress-bar progress-animated " style={{ width: `50%`, height: "10px", color: "blue" }} role="progressbar">
                <span class="sr-only"> 50% estimated</span>
              </div>
            </div>
          
            <div class="d-flex align-items-end mt-1 justify-content-between flex-direction-column">
              <span><small class="text-danger">Actual expenditure</small></span>
              {/* <h4 class="mb-0 fs-32 font-w800">42</h4> */}
            </div>
            <div class="progress default-progress">
              <div class="progress-bar progress-animated bg-danger" style={{ width: `52%`, height: "10px" }} role="progressbar">
                <span class="sr-only">52% % used</span>
              </div>
            </div>
          
          </div>

        </Grid>
        <Grid item xs={6}>
        <div class="card-body px-4">
            <h4 class="fs-18 font-w600 mb-3 text-nowrap">Completion Analysis</h4>
            <div class="d-flex align-items-end mt-1 justify-content-between flex-direction-column">
              <span><small class="text-info " style={{color:"info"}}>Estimated Progress  </small></span>
              {/* <h4 class="mb-0 fs-32 font-w800">42</h4> */}
            </div>
            <div class="progress default-progress">
       
              <div class="progress-bar progress-animated bg-info" style={{ width: `50%`, height: "10px", color: "blue" }} role="progressbar">
                <span class="sr-only"> 50% complete</span>
              </div>
            </div>
          
            <div class="d-flex align-items-end mt-1 justify-content-between flex-direction-column">
              <span><small class="text-success">Actual Progress</small></span>
              {/* <h4 class="mb-0 fs-32 font-w800">42</h4> */}
            </div>
            <div class="progress default-progress">
              <div class="progress-bar progress-animated bg-success" style={{ width: `52%`, height: "10px" }} role="progressbar">
                <span class="sr-only">52% % Complete</span>
              </div>
            </div>
          
          </div>
        </Grid>
      <Grid item xs={12} md={12}>
      <Timeline/>
      </Grid>
      <Grid item xs={12} md={12}>
      <Financiers/>
      </Grid>

      </Grid>
    </div>
  )
}

export default ProgressAnalytics