import { Box, Card, Grid } from '@mui/material'
import React from 'react'
import ActivitiesBarChart from './ActivitiesBarChart'
import ActivitiesComplete from './ActivitiesComplete'
import ImpactAnalysis from './ImpactAnalysis'
import RadialChart from './RadialChart'

export default function CompanyAnalytics() {
  return (
    <div>
         <Grid container spacing={2}>
  <Grid item xs={12} sm={6} md={8} >
    <Card style={{position:"relative"}}>
      <ActivitiesBarChart/>
    </Card>
  </Grid>
  <Grid item xs={4}>
  <Box style={{position:"relative"}}>
      <ImpactAnalysis/>
      <ActivitiesComplete/>
</Box>
  </Grid>
  <Grid item xs={8}>
  <Card style={{position:"relative"}}>
  <ActivitiesComplete/>
        </Card>
  </Grid>
  <Grid item xs={4}>
  <Card style={{position:"relative"}}>
  <RadialChart/>
        </Card>
  </Grid>
</Grid> 
    </div>
  )
}
