import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import { Container } from "@mui/system"
import { useRef } from "react";
import image from "../../Assets/custom/health.jpeg"
import image2 from "../../Assets/custom/water.png"
import image3 from "../../Assets/custom/roads.png"
import "leaflet/dist/leaflet.css";
import { Option, Select, Typography } from "@mui/joy";
import { Box, Card, Divider, Grid } from "@mui/material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const StyledPop = styled(Popup)`
  background-color: red;
  border-radius: 0;
  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }

  .leaflet-popup-tip-container {
    visibility: hidden;
  }
`;

const icon = new Icon({
  // iconUrl: {image},
  iconUrl: require("../../Assets/custom/red.jpeg"),
  iconSize: [15, 15],
  iconAnchor: [17, 46],
  popupAnchor: [4, -43]
});

const InProgressicon = new Icon({
  // iconUrl: {image},
  iconUrl: require("../../Assets/custom/yellow.jpg"),
  iconSize: [15, 15],
  iconAnchor: [17, 46],
  popupAnchor: [4, -43]
});
const Completeicon = new Icon({
  // iconUrl: {image},
  iconUrl: require("../../Assets/custom/green.png"),
  iconSize: [15, 15],
  iconAnchor: [17, 46],
  popupAnchor: [4, -43]
});

const ProjectMaps = () => {
  const [position, setPosition] = useState(null);
  const [center, setCenter] = useState({ lat: 36.8219, lng: 1.2921 })
  const ZOOM_LEVEL = 11
  const mapref = useRef()

  const towns = [
    { town: "Dagorreti", Latitude: -1.3000, Longitude: 36.7667 ,status:"complete"},
    { town: "kitengela", Latitude: -1.51667, Longitude: 36.85  ,status:"inprogress"},
    { town: "karen", Latitude: -1.311752, Longitude: 36.698598 ,status:"outof" },
    { town: "ngong", Latitude: -1.3667, Longitude: 36.6333,status:"complete" },
    { town: "riruta", Latitude: -1.303957, Longitude: 36.746471,status:"complete" },
    { town: "embakasi", Latitude: -1.3000, Longitude: 36.9167,status:"complete" },
    {town: "Ruaraka",  Latitude: -1.245672 , Longitude: 36.881528 ,status:"complete"},
    { town: "ngara",  Latitude: -1.274665, Longitude:36.829065 ,status:"inprogress"},
    { town: "race course",  Latitude: -1.3058,  Longitude: 36.7392,status:"outof"}

  ]
  const handleOnContextMenu = useCallback(
    (event) => {
      setPosition([event.latlng.lat, event.latlng.lng]);
    },
    [setPosition]
  );

  return (
    <Box>
                 
   
     
   
    <Container style={{margin:"7px"}} >
      <MapContainer style={{ height: "500px",width:"700px"}}
        center={[ -1.286389,36.817223]}
        // center={center}
        zoom={10}
        ref={mapref}
        oncontextmenu={handleOnContextMenu}
      >
        <TileLayer
          url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=BAhcmFoujuCMFXBhYGdT"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      
        {/* <Marker position={[ -1.286389,36.817223]} icon={icon}>
          <Popup>
            <b>project location </b>p
          </Popup>
        </Marker> */}
          {towns.map((town,index)=>{
            console.log("town",[town.Latitude,town.Longitude])
            return(
         <Marker key={index} position={[town.Latitude,town.Longitude]} icon={town.status==="outof"?icon:
         town.status==="complete"?Completeicon:InProgressicon }>
          <Popup>
            <b>{town.town} </b>p
          </Popup>
        </Marker>
      )})}

        {/* {position && (
          <StyledPop position={position} onClose={() => setPosition(null)}>
            <div>
              <h2>menu</h2>
            </div>
          </StyledPop>
        )}

        {position && <Marker position={position} icon={icon} />} */}
      </MapContainer>
    </Container>
    </Box >
  );
};
export default  ProjectMaps;
