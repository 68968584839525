import { Card, CardActionArea, Divider, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ProfileAvatar from '../../Utils/Components/Initials'
import { LocalStorage } from '../../Utils/Hooks/useLocalStorage';
import CompanyTab from './CompanyTab';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import ProjectsAnalysis from './ProjectAnalytics';

export default function CompanyProfile3() {
  const user = LocalStorage("user");
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card sx={{ p: 2 }}>
            <Box sx={{ m: 2 }} style={{ display: "flex" }}>
              <ProfileAvatar name={user && user.name} />
              <Typography sx={{ m: 1 }}>{user.name}</Typography>
            </Box>

            {/* <Typography sx={{ m: 2 }}>{user.email}</Typography> */}
            {/* <Typography sx={{ m: 2 }}>0712345678</Typography> */}
          </Card>
          <Typography variant="h6" sx={{color: "#006811",display:"flex",justifyContent:"center",alignItems:"center",m:2}}>Project Analytics</Typography>
         < ProjectsAnalysis/>
        </Grid>
        <Grid item xs={8}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ position: "relative" }}>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Card sx={{ p: 1, m: 1 }} style={{ backgroundColor: "#720000", width: "40px" }}>
                      <AccountTreeOutlinedIcon sx={{ color: "white" }} />
                    </Card>

                    <div style={{ float: "right", flexDirection: "column", margin: "2px" }}>
                      <Typography variant="paragraph"> Overdue Projects</Typography>
                      <Typography variant="h5">6%</Typography>
                    </div>

                  </Box>
                  <Divider />
                  <Typography sx={{ m: 2 }}><span style={{ color: "#006811" }}>-55% </span> than last week</Typography>

                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ position: "relative" }}>
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Card sx={{ p: 1, m: 1 }} style={{ backgroundColor: "#006811", width: "40px" }}>
                      <AccountTreeOutlinedIcon sx={{ color: "white" }} />
                    </Card>
                    <div style={{ float: "right", flexDirection: "column", margin: "2px" }}>
                      <Typography variant="paragraph">Complete Projects</Typography>
                      <Typography variant="h5">60%</Typography>
                    </div>

                  </Box>
                  <Divider />
                  <Typography sx={{ m: 2 }}><span style={{ color: "#006811" }}>+55% </span> than last month</Typography>

                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ position: "relative" }}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Card sx={{ p: 1, m: 1 }} style={{ backgroundColor: "#ef9f02", width: "40px" }}>
                      <FormatListBulletedOutlinedIcon sx={{ color: "white" }} />
                    </Card>
                    <div style={{ float: "right", flexDirection: "column", margin: "2px" }}>
                      <Typography variant="paragraph">Activities in progress</Typography>
                      <Typography variant="h5">100</Typography>
                    </div>

                  </Box>
                  <Divider />
                  <Typography sx={{ m: 2 }}><span style={{ color: "#006811" }}>+55% </span> than last month</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <Card style={{ position: "relative" }}>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Card sx={{ p: 1, m: 1 }} style={{ backgroundColor: "#5e30f5", width: "40px" }}>
                    <MoneyOutlinedIcon sx={{ color: "white" }} />
                  </Card>
                  <div style={{ float: "right", flexDirection: "column", margin: "2px" }}>
                    <Typography variant="paragraph"> Total Amount Spent</Typography>
                    <Typography variant="h5">1,000,000</Typography>
                  </div>

                </Box>
                <Divider />
                <Typography sx={{ m: 2 }}><span style={{ color: "#006811" }}>+55% </span> than last month</Typography>

              </Card>
            </Grid>
        </Grid>
        <Card sx={{ mt: 2 }}>
          <CompanyTab />
        </Card>
      </Box>
    </Grid>
  </Grid >
    </div >
  )
}
