import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ProjectCompletionStatus from './ProjectCompletion';

export default function  ProjectsAnalysis() {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        variant="scrollable"
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value={1} label="Upcoming Deadlines" />
        <Tab value={2} label="Complete Projects" />
        <Tab value={3} label="In Progress" />
        <Tab value={4 }label="Overdue " />
      </Tabs>
      <Box sx={{ padding: 2 }}>
      {value === 1 && (
          <Box>
            <ProjectCompletionStatus/>
          </Box>
        )}
         {value === 2 && (
          <Box>
             <ProjectCompletionStatus/>
          </Box>
        )}
         {value === 3 && (
          <Box>
            <ProjectCompletionStatus/>
          </Box>
        )}
         {value === 4 && (
          <Box>
             <ProjectCompletionStatus/>
          </Box>
        )}
      </Box>
    </Box>
  );
}