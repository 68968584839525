import { Grid } from '@mui/material'
import React from 'react'
import ActivityComparison from './ActivityComparison'
import MilestoneFinancial from './BudgetComparison'
import ImpactComparison from './ImpactComparisonn'

export default function ProjectChart() {
  return (
    <div>
        <Grid container spacing={2}>
  <Grid item xs={12}>
    <ActivityComparison/>
  </Grid>
  <Grid item xs={12} sm={6}>
  <MilestoneFinancial/>
  </Grid>
  <Grid item xs={12} sm={5}>
    
  <ImpactComparison/>
  </Grid>
  </Grid>
    </div>
  )
}
